<template>
  <div class="table-box">
    <div class="regular">
      <div class="flex">
        <div class="rulename flex">
          <el-input ref="unfold" v-model="name" :placeholder="$t('common.pleaseEnter')+$t('codingRule.ruleName')" :disabled="unfold"></el-input>
          <i class="el-icon-edit edit cursor" @click="focus"></i>
        </div>
        <el-button
          type="primary"
          class="primaryColor primaryStyle"
          @click="interposition(4)"
          :disabled="floor"
        >{{$t('codingRule.addFloor')}}</el-button>
        <el-button
          type="primary"
          class="primaryColor primaryStyle"
          @click="interposition(5)"
          :disabled="classify"
        >{{$t('codingRule.addCodification')}}</el-button>
        <el-button
          type="primary"
          class="primaryColor primaryStyle"
          @click="interposition(6)"
          :disabled="running"
        >{{$t('codingRule.addSerialNumber')}}</el-button>
        <el-button type="primary" class="primaryColor primaryStyle" @click="interposition(7)">{{$t('codingRule.additiveAttribute')}}</el-button>
        <el-button type="primary" class="primaryColor primaryStyle" @click="interposition(8)">{{$t('codingRule.addUserDefined')}}</el-button>
        <!-- <div class="group-buttons cursor" @click="interposition(7)">插入属性字段</div>
        <div class="group-buttons cursor" @click="interposition(8)">插入自定义</div>-->
      </div>
      <div style="overflow: auto">
        <div class="subject flex">
          <div
            v-for="(item,i) in codingRuleDetailss"
            :key="i"
            class="subject-coded subject-text subject-text-now"
            :class="[i+1 == codingRuleDetailss.length  ?'subject-now-last' : '']"
          >
            {{i+1}}
            <span class="el-icon-delete cursor delete-icon" @click="handromve(item,i)"></span>
          </div>
        </div>
        <draggable
          v-model="codingRuleDetailss"
          group="people"
          :animation="300"
          :scrollSpeed="100"
          :force-fallback="true"
          style="display:-webkit-box"
          :fallbackClass="true"
           :disabled="disabled"
          @start="onStart"
          @end="onEnd"
        >
          <div
            v-for="(coded,i) in codingRuleDetailss"
            :key="i"
            class="subject-coded subject-codedbg"
             :class="disabledindex==1 ? 'cursor' : ''"
            style="width: 276px"
            @click="codeindexSelect(i,coded)"
            @mousedown="codeindexSelect(i,coded)"
          >
            <div
              class="subject-tow"
              :class="[i+1 == codingRuleDetailss.length  ?'subject-tow-last' : '',,i==codeindex ?'subject-now-bg' : '']"
            >
              <div class="subject-heade-A cursor" @click="disabled=false"  @mouseover="disabled=false" @mouseout="disabled=true">
                <div style="width:9px">...</div>
              </div>
              <div
                class="subject-text "

                style="font-weight: 800;"
              >{{coded.name}}</div>
              <div
                style="justify-content:center ;position: absolute; top: 50px;width: 100%; display: flex;"
                class="flex"
              >
                <div style="width: 24px;height: 2px;background:  #373737;"></div>
              </div>
              <div class="subject-text subject-notice " >{{notice[coded.type-1]}}</div>
            </div>
           <div
            @click="codeindexSelect(i,coded)"
            class="subject-tow flex subject-tow-flxe"
            :class="[i+1 == codingRuleDetailss.length  ?'subject-tow-last' : '',,i==codeindex ?'subject-now-bg' : '']"
          >

             <div
              class="subject-text"
              v-if="coded.type==1 ||coded.type==2 || coded.type==4 || coded.type==6"
            >
              <el-input
                v-model="coded.value"
                :disabled="true"
                style="background:#fff;color: #888888 ;"
              ></el-input>
            </div>
            <div class="subject-text" v-else-if="coded.type==5">
              <div class="array array-xina"></div>
              <div
                class="array array-xina"
                style="height: 13px;
                  transform: rotate(45deg);
                  left: -10px;"
              ></div>
              <div class="array">
                <div class="arry-lien">
                  映
                  射
                  顺
                  序
                </div>
              </div>
              <div class="flex">
                <div class="adorn"></div>
                <span class="adorn-text">请选择映射标准</span>
                <div class="adorn adorn-right"></div>
              </div>
              <div class="flex" style="justify-content: center" v-if="SpaceId==1">
                <el-button type="primary" class="primaryStyle" @click="SpaceId=1">公司标准</el-button>
                <el-button type="primary" class="primaryColor primaryStyle" @click="SpaceId=2">公共标准</el-button>
              </div>
              <div class="flex" style="justify-content: center" v-if="SpaceId==2">
                <el-button type="primary" class="primaryColor primaryStyle" @click="SpaceId=1">公司标准</el-button>
                <el-button type="primary" class="primaryStyle" @click="SpaceId=2">公共标准</el-button>
              </div>
              <div v-if="SpaceId==1">
                <el-cascader
                  v-model="coded.value"
                  @change="handItemChange(coded)"
                  ref="optionscascader"
                  clearable
                  :options="options"
                  :props="props"
                  :show-all-levels="false"
                  :placeholder="$t('common.pleaseChoose')"
                ></el-cascader>
              </div>
              <div v-else>
                <el-cascader
                  v-model="coded.value"
                  @change="handItemChange(coded)"
                  ref="optionscascader"
                  clearable
                  :options="codeItems"
                  :props="props"
                  :show-all-levels="false"
                  :placeholder="$t('common.pleaseChoose')"
                ></el-cascader>
              </div>
              <div class="flex" style="padding-top:30px">
                <div class="adorn"></div>
                <span class="adorn-text">请添加映射对象</span>
                <div class="adorn adorn-right"></div>
              </div>
              <draggable
                v-model="CategoryCodeEntry"
                group="peoplex"
                :animation="300"
                :scrollSpeed="100"
                :force-fallback="true"
                :fallbackClass="true"
                :disabled="disabled"
                style="padding-left:12% ;height: 200px;overflow: hidden;overflow-y: auto;width:100%"
                @end="onEndx"
                 @start="onStart"
              >
                <div
                  v-for="(item,index) in CategoryCodeEntry"
                  :key="index"
                  class="subject-text subject-color flex"
                  style="width:180px"
                  @mouseover="mousedownreveal(index)"
                >
                  <div class="subject-heade cursor" @click="disabled=false" @mouseover="disabled=false" @mouseout="disabled=true">
                    <div style="width:9px">...</div>
                  </div>
                  <el-input v-model="item.value" @blur="blurEntry(item.value,index)"></el-input>
                  <div v-if="reveal==index" class="reveal-flex">
                    <div @click="entrys" class="icon-box">
                      <img
                        v-if="index+1==CategoryCodeEntry.length"
                        :src="require('../../assets/add.png')"
                        class="entrysbt icon-Category cursor"
                      />
                    </div>
                    <div class="icon-box" @click="Categoryromve(item,index)">
                      <span class="el-icon-delete cursor icon-Category" style="top:20%"></span>
                    </div>
                  </div>
                </div>
              </draggable>
              <!-- <div class="entry">
                <el-button type @click="entrys">新增词条</el-button>
              </div>-->
            </div>
            <div v-else class="subject-text subject-color">
              <el-input v-model="coded.value"></el-input>
            </div>
          </div>
        </div>
        </draggable>

      </div>
      <div class="regular-bootom flex">
        <el-button type="primary" @click="getback" class="primaryColor">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="cancel" class="primaryColor">{{$t('common.reset')}}</el-button>
        <el-button type="primary" class="primaryColor2" @click="errorSetting">{{$t('codingRule.saveSettings')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      reveal: -1,
      disabled: true,
      disabledindex: 0,
      props: {
        value: 'id',
        label: 'excelName',
        emitPath: false,
        children: 'children'
      },
      CategoryCodeEntry: [{ value: '族与类型' }],
      floor: false,
      classify: false,
      running: false,
      codingRuleDetailss: [],
      codeindex: -1,
      codingRuleId: 1,
      monomerList: [],
      unfold: false,
      notice: [
        '读取企业代号作为该编码段',
        '读取项目代号作为该编码段',
        '自主选取单体作为该编码段',
        '读取表格/模型楼层属性作为该编码段段',
        '根据用户设置的参数优先级顺序,与"分类编码表"产生映射,从而形成编码段',
        '根据实例个数自动生成流水序列段',
        '读取设定的参数作为编码段',
        '手动输入一串编码作为该编码段'
      ],
      parameterInfoList: [
        {
          id: -1,
          name: '企业编码',
          parameterId: -1
        },
        {
          id: -2,
          name: '项目编码',
          parameterId: -1
        },
        {
          id: -3,
          name: '单体编码',
          parameterId: -1
        },
        {
          id: -4,
          name: '楼层',
          parameterId: -1
        },
        {
          id: -5,
          name: '分类编码',
          parameterId: -1
        },
        {
          id: -6,
          name: '流水号',
          parameterId: -1
        }
      ],
      name: '默认规则',
      checkList: [],
      check: false,
      parameterList: [],
      propertyList: [],
      codingRule: {},
      drag: false,
      options: [],
      excelname: '',
      optionsId: '',
      codeItems: [],
      SpaceId: 1
    }
  },
  created () {
    this.init()
    this.getCodeList()
    // this.getMonomerCodeAndFloorCode();
    this.GetCodeLibList()
  },
  watch: {
    disabledindex (newval) {
      if (newval == 1) {
        console.log(1)
        setTimeout(() => {
          this.disabled = false
        }, 100)
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    mousedownreveal (index) {
      this.reveal = index
    },

    // 获取公共编码
    GetCodeLibList () {
      this.$axios.get('/api/FormHandle/GetCodeLibList?SpaceId=0').then(res => {
        res.forEach(item => {
          item.children = item.codingListDto
        })
        this.codeItems = res
      })
    },
    // 获取公司编码

    codeindexSelect (i, coded) {
      this.codeindex = i
      coded.codeindex = i
    },
    focus () {
      this.unfold = false
      this.$refs.unfold.focus()
    },
    entrys () {
      let obj = { value: '族与类型' }
      this.CategoryCodeEntry.push(obj)
    },
    handItemChange (item) {
      let codingSet = this.$refs.optionscascader[0].getCheckedNodes()[0].data
      this.excelname =
        codingSet.excelName + '?' + codingSet.id + '?' + this.SpaceId
      // 字段以文件名和文件id一级数据类型组成(1为公司，2位公共)
      item.excelname = codingSet.excelUrl + '?' + codingSet.matches
      console.log(item)
    },
    Categoryromve (item, i) {
      if (this.CategoryCodeEntry.length > 1) {
        this.CategoryCodeEntry.splice(i, 1)
      } else {
        this.$message.error('必须有一个且不能为空')
      }
    },
    blurEntry (e, index) {
      console.log(/[;]/.test(e))
      console.log()
      if (/[;]/.test(e) || /[；]/.test(e)) {
        this.$message.error('不能输入分号')
        this.CategoryCodeEntry[index].value = ''
      }
    },
    handromve (item, i) {
      this.codeindex = -1
      this.codingRuleDetailss.splice(i, 1)

      // if (item.type != 7 && item.type != 8) {
      //   this.checkList = this.checkList.filter(e => {
      //     return e != item.name;
      //   });
      // } else {
      if (item.type == 4) {
        this.floor = false
      }
      if (item.type == 5) {
        this.classify = false
      }
      if (item.type == 7) {
        this.running = false
      }
      if (item.sort) {
        this.propertyList = this.propertyList.filter(e => {
          return e.sort != item.sort
        })
      } else {
        this.propertyList = this.propertyList.filter(e => {
          return e.codingRule != item.codingRule
        })
      }
      // }
    },
    getback () {
      this.$router.push('/home/codingRules')
    },
    onStart () {
      this.drag = true
      this.disabled = false
      this.disabledindex = 1
    },
    init () {
      this.codingRule = {}
      if (localStorage.getItem('codingRule')) {
        this.codingRule = JSON.parse(localStorage.getItem('codingRule'))
      }
      this.parameterList = []
      this.propertyList = []
      console.log(this.codingRule)
      if (this.codingRule.id) {
        this.unfold = true
        this.codingRule.codingRuleDetailss.forEach(item => {
          if (item.type == 7 || item.type == 8) {
            this.propertyList.push(item)
          } else {
            this.parameterList.push(item)
            if (item.type == 5) {
              this.classify = true
              this.excelname =
                item.name.split('?')[1] +
                '?' +
                item.name.split('?')[2] +
                '?' +
                item.name.split('?')[3]
              item.excelname = item.value
              item.value = Number(item.name.split('?')[2])
              this.SpaceId = item.name.split('?')[3]
              item.name = item.name.split('?')[0]
              console.log(this.excelname)
              let addarr = item.categoryCodeEntry.split(';')
              this.CategoryCodeEntry = []
              addarr.forEach(excelname => {
                this.CategoryCodeEntry.push({ value: excelname })
              })
            } else if (item.type == 4) {
              this.floor = true
            } else if (item.type == 6) {
              this.running = true
            }
            // this.checkList.push(item.name);
          }
        })
      }
      this.name = this.codingRule.name
      if (this.codingRule.codingRuleDetailss) {
        this.codingRuleDetailss = this.codingRule.codingRuleDetailss
      } else {
        this.codingRuleDetailss = []
      }
    },
    cancel () {
      if (this.codingRule.id) {
        this.init()
        return 0
      }
      this.propertyList = []
      this.parameterList = []
      this.checkList = []
      this.codingRuleDetailss = [...this.parameterList, ...this.propertyList]
    },
    getCodeList () {
      let sid = localStorage.getItem('eleSpaceId')
      this.$axios
        .get(`/api/formhandle/getcodeliblist?SpaceId=${sid}`)
        .then(res => {
          res.forEach(item => {
            item.children = item.codingListDto
          })
          this.options = res
        })
    },
    interposition (item) {
      let obj = {}
      obj.value = ''
      obj.codingRule = 'code' + this.codingRuleId
      this.codingRuleId = this.codingRuleId + 1
      obj.type = item
      if (item == 4) {
        this.floor = true
        obj.name = '楼层编码'
        obj.value = '楼层号'
        this.propertyList.push(obj)
      } else if (item == 5) {
        if (this.options.length) {
          this.classify = true
          obj.name = '分类编码'
        } else {
          this.$message.warning('请先设置分类编码')
          return
        }
      } else if (item == 6) {
        this.running = true
        obj.name = '流水号'
        obj.value = '自动流水号'
        this.propertyList.push(obj)
      } else if (item == 7) {
        obj.name = '属性字段'
        this.propertyList.push(obj)
      } else {
        obj.name = '自定义属性'
        this.propertyList.push(obj)
      }
      if (this.codeindex == -1) {
        this.codingRuleDetailss.push(obj)
      } else {
        this.codingRuleDetailss.splice(this.codeindex + 1, 0, obj)
      }
      console.log(this.codingRuleDetailss)
    },
    onEndx () {
      this.disabled = true
      this.disabledindex = 0
    },
    onEnd () {
      if (this.codeindex == -1) {
        return 0
      }
      this.codingRuleDetailss.forEach((item, i) => {
        if (this.codeindex == item.codeindex) {
          console.log(i)
          this.codeindex = i
          item.codeindex = i
        } else {
          item.codeindex = -1
        }
      })
      this.drag = false
      this.disabled = true
      this.disabledindex = 0
    },
    errorSetting () {
      if (!this.name) {
        this.$message.warning('请设置规则名称')
        return 0
      }
      let data = {}
      let code = 1
      let bode = 0
      let category = []
      let yuanshicodingRuleDetailss = JSON.parse(JSON.stringify(this.codingRuleDetailss))
      this.codingRuleDetailss.forEach((item, i) => {
        item.categoryCodeEntry = ''
        if (!item.value) {
          code = 0
        }
        if (item.type == 5) {
          if (!this.CategoryCodeEntry.length) {
            bode = 1
          }
          this.CategoryCodeEntry.forEach(e => {
            if (!e.value) {
              bode = 1
            }
            category.push(e.value)
          })
          if (bode) {
            return 0
          }
          item.value = item.excelname
          item.name = item.name + '?' + this.excelname
          item.categoryCodeEntry = category.join(';')
          delete item.excelname
        }
        item.sort = i + 1
        delete item.codingRule
        delete item.codeindex
        delete item.codingRuleId
      })
      console.log(this.codingRuleDetailss)
      if (!code) {
        this.$message.warning('有规则未设置内容')
        this.codingRuleDetailss = JSON.parse(JSON.stringify(yuanshicodingRuleDetailss))
        return 0
      }
      if (bode) {
        this.$message.warning('至少需要一条词条')
        this.codingRuleDetailss = JSON.parse(JSON.stringify(yuanshicodingRuleDetailss))
        return 0
      }
      if (!this.codingRule.id) {
        data = {
          name: this.name,
          codingRuleDetailss: this.codingRuleDetailss
        }
      } else {
        data = {
          id: this.codingRule.id,
          name: this.name,
          codingRuleDetailss: this.codingRuleDetailss
        }
      }
      this.$axios.post('/api/codingrule/editcodingrule', data).then(res => {
        this.$message({
          message: '添加成功',
          type: 'success'
        })
        this.$router.push('/home/codingRules')
      })
    }
  }
}
</script>
<style scoped>
.table-box {
  padding: 15px 25px;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  background-color: #e7e7e7;
}
.flex {
  display: -webkit-box;
}
.rulenamex {
  border-bottom: 1px solid #000;
}
.delete-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  color: red;
}
.primaryStyle {
  position: relative;
  height: 36px;
  font-size: 12px;
}
.primaryleft {
  position: absolute;
  right: 40px;
}
/deep/ .el-input__inner {
  border: 0;
  height: 33px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 2px;
  padding-right: 7px;
  overflow: hidden;
  background: #fff !important;
}
/deep/.el-input .is-disabled .el-input__inner {
  border-color: #fff;
}
/deep/ .el-input__suffix {
  right: -24px;
}
.regular {
  position: relative;
  background: #ffff;
  height: 100%;
  width: 99%;
  margin: 10px 0 0 0;
  border-radius: 8px;
  color: #000;
  padding: 10px;
}
.rulename {
  font-size: 14px;
  text-align: left;
  height: 34px;
  font-weight: 600;
  line-height: 34px;
  width: 300px;
  text-indent: 2px;
}
.group {
  position: relative;
  margin-right: 10px;
}
.cursor {
  cursor: pointer;
}
.group-buttons {
  width: 100px;
  height: 20px;
  margin-top: 10px;
  border: 1px rgba(110, 110, 110, 0.952) solid;
  line-height: 20px;
  margin-left: 20px;
  font-size: 12px;
}
.group > .group-button {
  width: 260px;
  margin-top: 10px;
  height: 20px;
  border-bottom: 1px #000 solid;
  line-height: 20px;
  font-size: 12px;
}
.arrow-right {
  position: relative;
  left: 80px;
  top: 0px;
}
/deep/.el-input .is-disabled .el-input__inner {
  color: hsl(194, 92%, 31%);
}
.checkbox {
  position: absolute;
  top: 36px;
  width: 260px;
  border: 1px rgb(82, 81, 81) solid;
  border-radius: 4px;
  background: #fff;
  z-index: 1;
}
.el-checkbox {
  display: flex;
  margin: 10px 0 10px 10px;
}
/deep/.el-checkbox__label {
  padding-left: 20px;
  font-weight: 600;
}
.subject {
  margin-top: 10px;
  padding: 10px 0;
  height: 600px;
}

.subject-coded-boder {
  border-left: 0px;
}
.subject-text {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  position: relative;
}
.edit {
  position: relative;
  top: -30px;
  left: -0px;
  color: #000;
  font-size: 16px;
}
.subject-color {
  color: #50c0e2;
  font-weight: 600;
}
.entry {
  position: absolute;
  bottom: 2px;
  right: 4px;
}
.subject {
  margin-top: 10px;
  padding: 10px 0;
  height: 22px;
}

.subject-coded {
  width: 275px;
}
.subject-coded-boder {
  border-left: 0px;
}
.subject-text {
  line-height: 50px;
  text-align: left;
}
.subject-color {
  color: #50c0e2;
  font-weight: 600;
}
.subject-text-now {
  position: relative;
  height: 30px;
  background: #f7f5f5;
  border: 1px solid #d8d8d8;
  border-top: 2px solid #475065;
  border-right: 0;
  text-align: center;
  line-height: 30px;
}
.subject-tow {
  border: 1px solid #d8d8d8;
  position: relative;
  border-bottom: 0;
}
.icon-Category {
  color: red;
  line-height: 100%;
  box-sizing: border-box;
  position: relative;
  top: 34%;
  left: -10%;
}
.subject-boomo {
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  height: 450px;
}
.subject-codedbg {
  background: #fff;
}
.subject-now-bg {
  background: #16151533;
}
.subject-tow-last {
  border-right: 1px solid #d8d8d8;
}
.subject-tow > .subject-text {
  height: 180px;
  text-align: center;
}
.subject-color-cursor {
  cursor: move;
}
.subject-notice {
  width: 100%;
  position: absolute;
  top: 80px;
  font-size: 14px;
  line-height: 34px;
  color: #666666;
}
.regular-bootom {
  position: absolute;
  bottom: 8%;
  right: 20px;
}
.primaryColor {
  background: #2f374a;
  border-color: #2f374a;
  padding: 10px 10px;
  text-align: center;
}
/deep/.el-input__inner {
  border-bottom: 1px solid #d8d8d8;
  width: 90%;
  text-align: center;
  border-radius: 0;
}
/deep/ .subject-color .el-input__inner {
  color: #50c0e2;
}
/deep/.el-select {
  width: 50%;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2f374a;
  border-color: #2f374a;
}
/deep/ .el-collapse {
  position: absolute;
  top: -5px;
  padding-left: 4px;
  margin-right: 10px;
  width: 258px;
  border: 0;
  border-bottom: 1px solid #4b4b4b;
}
/deep/ .el-collapse-item__header {
  border-bottom: 1px solid #4b4b4b;
  height: 38px;
  line-height: 38px;
}
/deep/.el-collapse-item__header {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #31b8fb;
  line-height: 30px;
  text-indent: 6px;
}
.subject-tow-flxe {
  justify-content: center;
  width: 274px;
  border-top: 0;
  height: 451px;
    border-bottom: 1px #d8d8d8 solid;
}
.primaryColor2 {
  background: #1aadf1;
}
.reveal-flex {
  display: flex;
  position: relative;
  left: -20%;
}
.entrysbt {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}
.adorn {
  width: 50px;
  height: 30px;
  background: url("../../assets/xiantiao.png") no-repeat;
  background-position: bottom;
}
.adorn-right {
  transform: rotate(180deg);
  background-position: top;
}
.adorn-text {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  color: #333333;
}
.subject-heade {
  width: 6px;
  margin: 0 auto;
  line-height: 6px;
  font-size: 17px;
  word-wrap: break-word;
  color: #000;
  padding-top: 12px;
}
.subject-heade-A {
  position: absolute;
  left: 10px;
  width: 20px;
  margin: 0 auto;
  line-height: 6px;
  font-size: 17px;
  word-wrap: break-word;
  color: #000;
  padding-top: 12px;
  z-index: 10;
  font-weight: 600;
}
.icon-box {
  width: 30px;
  height: 30px;
}
/deep/ .el-scrollbar__view,
.el-cascader-menu__list {
  display: flex !important;
}
.array {
  position: absolute;
  left: -7px;
  bottom: -56%;
}
.arry-lien {
  width: 10px;
  height: 91px;
  font-size: 9px;

  font-weight: 800;
  color: #bfbfbf;
  line-height: 60px;
}
.array-xina {
  height: 277px;
  background: #bfbfbf;
  width: 1px;
  left: -15px;
  bottom: -142%;
}
::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: #F5F5F5;
}
:-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    border-radius: 10px;
    background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44,rgb(60,186,146)),color-stop(0.72,rgb(253,187,45)),color-stop(0.86,rgb(253,187,45)));
    transition: 0.3s ease-in-out;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
</style>
